<template>
    <div>
        <adContent></adContent>
        <div class="cont-st cont-list">
            <div class="cont-st-h">
                <p>{{firstList.title}} GAMES</p>
                <div @click="goselect(firstList.title)">more</div>
            </div>
            <div class="cont-list-card" v-for="items in firstList.games" :key="items.id" @click="goDetails(items)">
                <img :src="items.logo" alt="">
                <p class="no-warp">{{items.title}}</p>
            </div>
            <span v-for="item in spanNum" :key="item+'s'"></span>
        </div>
        <div class="cont-st mid-list">
            <div class="cont-st-h">
                <p>{{newList.title}} GAMES</p>
                <div @click="goselect(newList.title)">more</div>
            </div>
            <div class="mid-list-card" v-for="items in newList.games" :key="items.id">
                <img :src="items.logo" alt="">
                <div class="mid-c-mid">
                    <p class="no-warp">{{items.title}}</p>
                    <div class="warp-2">{{items.synopsis}}</div>
                    <div>
                        <van-rate
                        v-model="items.rating"
                        readonly
                        :size="10"
                        color="#FFC900"
                        void-icon="star"
                        void-color="#eee"
                        />
                    </div>
                </div>
                <div class="play" @click="goDetails(items)">
                    Play
                </div>
            </div>
        </div>
        <div class="cont-st cont-list">
            <div class="cont-st-h">
                <p>{{gameList.title}} GAMES</p>
                <div @click="goselect(gameList.title)">more</div>
            </div>
            <div class="cont-list-card" v-for="items in gameList.games" :key="items.id" @click="goDetails(items)">
                <img :src="items.logo" alt="">
                <p class="no-warp">{{items.title}}</p>
            </div>
            <span v-for="item in spanNum" :key="item+'s'"></span>
        </div>
    </div>
</template>

<script>
import { getRandomNumbers, isMobile, scrollToTop } from "@/utils/api"
import eventBus from '@/utils/eventBus'
import { getGameByKey, getGame } from "@/api/games"
import adContent from "@/components/adContent/index.vue";
    export default {
        components: {
            adContent,
        },
        data() {
            return {
                gameList:[],
                value:4,
                firstList:{},
                newList:{},
                spanNum:0
            }
        },
        mounted() {
            if(isMobile()){
                this.spanNum = 3-2
            }else{
                this.spanNum = 11-2
            }
            let menukey = this.$route.params.menuKey;
            let is
            if(menukey){
                this.getMenuGame(menukey)
                is = 0
            }else{
                is = 1
            }
            this.getList(is);
            eventBus.$on('selectMenu',key=>{                    
                this.getMenuGame(key)
            });
        },
        methods: {
            goDetails(item){
                this.$router.push({
                    path:'/details',
                    query:{
                        id:item.id
                    }
                })
            },
            async getList(is){
                let gameList = await getGame();
                if(is){
                    this.gameList = {
                        title: 'All',
                        games:gameList
                    }
                }
                let firstList = await getGame({count:12});

                this.firstList = {
                    title: 'Hot',
                    games:firstList
                }
                let newList = await getGame({count:6});

                this.newList = {
                    title: 'New',
                    games:newList
                }
            },
            getRandomGames(str,end,len){
                let gamelist = getRandomNumbers(str,end,len);

                gamelist.forEach((item,i)=>{
                    gamelist.splice(i,1,this.allList[item])
                })

                return gamelist;
            },
            async getMenuGame(name){
                console.log(name);
                let games = await getGameByKey({category:name})
                this.gameList = {
                    title: name,
                    games
                }
                                
                eventBus.$emit('showmenu',0)
                eventBus.$emit('showfenlei',0)

                scrollToTop(1000)
            },
            goselect(item){
                this.$router.push({
                    path: '/selectGame',
                    query:{
                        id: 'more',
                        key: item
                    }
                })
            }
        },
    }
</script>

<style lang="less" scoped>
// .my-swipe{
//     margin-bottom: 28px;
//     .swipe-item{
//         padding: 12px 20px 0;
//         box-sizing: border-box;
//         display: flex;
//         justify-content: space-between;
//         img{
//             width: 165px;
//             height: 165px;
//             border-radius: 15px;
//         }
//     }
// }
// .cont-nine{
//     padding: 0 20px;
//     display: flex;
//     justify-content: space-between;
//     flex-wrap: wrap;
//     .nine-card{
//         width: 101px;
//         border-radius: 15px;
//         margin-bottom: 15px;
//         img{
//             border: 2px solid #FFFFFF;
//             width: 101px;
//             height: 101px;
//             border-radius: 15px;
//         }
//         p{
//             white-space: nowrap;
//             font-family: PingFangSC, PingFang SC;
//             font-weight: 600;
//             font-size: 14px;
//             color: #1E1B4C;
//             line-height: 20px;
//             text-align: left;
//         }
//     }
// }
// .cont-class{
//     display: flex;
//     margin-bottom: 16px;
//     padding: 0 20px;
//     justify-content: space-between;
//     div{
//         width: 80px;
//         height: 80px;
//         background: linear-gradient( 180deg, #FFFFFF 0%, #FFECEB 100%);
//         box-shadow: 0px 2px 8px 0px rgba(214,83,77,0.72);
//         border-radius: 10px;
//         border: 1px solid #FFFFFF;
//         img{
//             width: 54px;
//             height: 54px;
//             display: block;
//             margin: 0 auto;
//         }
//         p{
//             text-align: center;
//             font-family: PingFangSC, PingFang SC;
//             font-weight: 500;
//             font-size: 14px;
//             color: #994B46;
//             line-height: 20px;
//             font-style: normal;
//         }
//     }
// }
.cont-st{
    padding: 0 20px;
    box-sizing: border-box;
    margin-bottom: 25px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .cont-st-h{
        width: 100%;
        height: 40px;
        // background: #504BA9;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        p{
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 18px;
            color: #1EDCB4;
            line-height: 25px;
            text-align: left;
            font-style: normal;
            // margin-left: 12px;
        }
        div{
            margin-right: 12px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 16px;
            color: #158870;
            line-height: 22px;
            text-align: left;
            font-style: normal;
            text-transform: uppercase;
        }
    }
    .cont-st-card{
        margin: 12px 0;
        padding-top: 6px;
        box-sizing: border-box;
        width: 101px;
        // height: 152px;
        // background: #FFFFFF;
        border-radius: 11px;
        text-align: center;
        img{
            width: 101px;
            height: 101px;
            border-radius: 15px;
        }
        p{
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 14px;
            color: #000000;
            line-height: 20px;
            font-style: normal;
        }
        div{
            display: flex;
            justify-content: space-between;
            align-items: center;
            span{
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 14px;
                color: #FFFFFF;
                line-height: 20px;
                text-align: right;
                font-style: normal;
            }
        }
    }
}
.cont-ed{
    .cont-ed-card{
        width: 162px;
        height: 86px;
        padding: 5px;
        margin: 8px 0 0 0;
        box-sizing: border-box;
        background: #FFFFFF;
        border-radius: 10px;
        display: flex;
        align-content: center;
        justify-content: space-between;
        .ed-card-l{
            width: 76px;
            height: 76px;
            border-radius: 10px;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .ed-card-r{
            width: 70px;
            overflow: hidden;
            p{
                margin-bottom: 14px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 14px;
                color: #000000;
                line-height: 20px;
                text-align: left;
                font-style: normal;
            }
            .play{
                margin: 0 auto;
                width: 63px;
                height: 21px;
                text-align: center;
                line-height: 21px;
                background: #504BA9;
                border-radius: 12px;

                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 9px;
                color: #FFFFFF;
                font-style: normal;
                text-transform: uppercase;
            }
        }
    }
}
.mid-list{
    .mid-list-card{
        width: 335px;
        height: 80px;
        margin: 7px 0;
        background: #EFFFFC;
        display: flex;
        align-items: center;
        justify-content: space-between;
        img{
            width: 80px;
            height: 80px;
            background: #D8D8D8;
            border-radius: 13px;
            border: 1px solid #FFFFFF;
        }
        .mid-c-mid{
            width: 162px;
            p{
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 14px;
                color: #000000;
                text-align: left;
                font-style: normal;
            }
            div{
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 12px;
                color: #747474;
                line-height: 17px;
                text-align: left;
                font-style: normal;
            }
        }
        .play{
            width: 74px;
            height: 25px;
            background: linear-gradient( 180deg, #36E7D2 0%, #11BFB2 100%);
            border-radius: 12px;
            line-height: 25px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 10px;
            color: #FFFFFF;
            text-align: center;
            font-style: normal;
        }
    }
}
.cont-list{
    // &::after{
    //     content: '   ';
    //     flex: auto;
    //     display: block;
    //     width: 100px;
    // }
    .cont-list-card{
        width: 100px;
        margin: 16px 0 0 0;
        img{
            width: 100px;
            height: 100px;
            border-radius: 15px;
            border: 2px solid #FFFFFF;
        }
        p{
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            font-size: 14px;
            color: #000;
            line-height: 20px;
            text-align: left;
            font-style: normal;
        }
    }
    span{
        width: 100px;
    }
}
.no-warp{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.warp-2{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>